import documentBrown from '../images/document-brown.png'
import buildingImage from '../images/building.png'
import marriageRingsImage from '../images/marriage.png'
import courtImage from '../images/court.png'
import balanceImage from '../images/balance.png'
import passportImage from '../images/passport.png'
import stampImage from '../images/stamp.png'
import checkBoxImage from '../images/check-box.png'
import calendarImage from '../images/calendar.png'

const vocabulary = {
  //home screen (1 element Russian, 2 element English)

  siteName: ['Консульский юрист', 'CONSUL ONLINE', '领事律师', 'CONSUL ONLINE'],
  slogan1: [
    'Услуги по подготовке документов онлайн',
    'Online document preparation services for',
    '在线文件准备服务',
    'Servicios de preparación de documentos en línea para',
  ],
  slogan2: [
    'для подачи в консульства России',
    'submission to Russian consulates.',
    '提交到俄罗斯领事馆。',
    'presentación a los consulados rusos.',
  ],
  ourServices: [
    'наши услуги',
    'our services',
    '我们的服务',
    'nuestros servicios',
  ],
  aboutUs1: [
    'Консульский юрист online — это правовая помощь гражданам России и иностранным гражданам в оформлении документов, необходимых для подачи в Российские консульские учреждения за рубежом',
    'CONSUL ONLINE is legal assistance to citizens of Russia and foreign citizens in preparing documents required for submission to Russian consular offices abroad',
    '在线领事律师是为俄罗斯公民和外国公民提供法律帮助，协助准备提交给俄罗斯驻外领事馆所需的文件',
    'CONSUL ONLINE es asistencia legal a ciudadanos de Rusia y ciudadanos extranjeros en la preparación de documentos requeridos para la presentación a los consulados rusos en el extranjero.',
  ],
  aboutUs2: [
    'Наша миссия — упростить процесс получения российских государственных услуг за пределами России',
    'Our mission is to simplify the process of obtaining Russian government services outside of Russia.',
    '我们的使命是简化在俄罗斯以外地区获取俄罗斯政府服务的过程。',
    'Nuestra misión es simplificar el proceso de obtención de servicios gubernamentales rusos fuera de Rusia.',
  ],
  serviceObtaining: [
    'процесс получения услуги',
    'Service obtaining process',
    '服务获取过程',
    'Proceso de obtención de servicios',
  ],

  euCountryIndexes: [
    2, 13, 15, 33, 35, 46, 47, 48, 49, 58, 70, 73, 74, 87, 93, 94, 98, 103, 104,
    121, 122, 123, 126, 127, 128,
  ],
  nameCountry: ['Страна', 'Country', '国家', 'País'],
  search: ['Поиск', 'Search', '搜索', 'Buscar'],
  countries: [
    ['Абхазия', 'Abkhazia', '阿布哈兹', 'Abjasia'],
    ['Австралия', 'Australia', '澳大利亚', 'Australia'],
    ['Австрия', 'Austria', '奥地利', 'Austria'],
    ['Азербайджан', 'Azerbaijan', '阿塞拜疆', 'Azerbaiyán'],
    ['Албания', 'Albania', '阿尔巴尼亚', 'Albania'],
    ['Алжир', 'Algeria', '阿尔及利亚', 'Argelia'],
    ['Ангола', 'Angola', '安哥拉', 'Angola'],
    ['Аргентина', 'Argentina', '阿根廷', 'Argentina'],
    ['Армения', 'Armenia', '亚美尼亚', 'Armenia'],
    ['Афганистан', 'Afghanistan', '阿富汗', 'Afganistán'],
    ['Бангладеш', 'Bangladesh', '孟加拉国', 'Bangladés'],
    ['Бахрейн', 'Bahrain', '巴林', 'Baréin'],
    ['Беларусь', 'Belarus', '白俄罗斯', 'Bielorrusia'],
    ['Бельгия', 'Belgium', '比利时', 'Bélgica'],
    ['Бенин', 'Benin', '贝宁', 'Benín'],
    ['Болгария', 'Bulgaria', '保加利亚', 'Bulgaria'],
    ['Боливия', 'Bolivia', '玻利维亚', 'Bolivia'],
    [
      'Босния и Герцеговина',
      'Bosnia and Herzegovina',
      '波斯尼亚和黑塞哥维那',
      'Bosnia y Herzegovina',
    ],
    ['Ботсвана', 'Botswana', '博茨瓦纳', 'Botsuana'],
    ['Бразилия', 'Brazil', '巴西', 'Brasil'],
    ['Бруней', 'Brunei', '文莱', 'Brunéi'],
    ['Бурунди', 'Burundi', '布隆迪', 'Burundi'],
    ['Ватикан', 'Vatican City', '梵蒂冈', 'Vaticano'],
    ['Великобритания', 'United Kingdom', '英国', 'Reino Unido'],
    ['Венгрия', 'Hungary', '匈牙利', 'Hungría'],
    ['Венесуэла', 'Venezuela', '委内瑞拉', 'Venezuela'],
    ['Вьетнам', 'Vietnam', '越南', 'Vietnam'],
    ['Габон', 'Gabon', '加蓬', 'Gabón'],
    ['Гайана', 'Guyana', '圭亚那', 'Guayana'],
    ['Гана', 'Ghana', '加纳', 'Ghana'],
    ['Гватемала', 'Guatemala', '危地马拉', 'Guatemala'],
    ['Гвинея', 'Guinea', '几内亚', 'Guinea'],
    ['Гвинея-Бисау', 'Guinea-Bissau', '几内亚比绍', 'Guinea-Bisáu'],
    ['Греция', 'Greece', '希腊', 'Grecia'],
    ['Грузия', 'Georgia', '格鲁吉亚', 'Georgia'],
    ['Дания', 'Denmark', '丹麦', 'Dinamarca'],
    ['Джибути', 'Djibouti', '吉布提', 'Yibuti'],
    ['Египет', 'Egypt', '埃及', 'Egipto'],
    ['Замбия', 'Zambia', '赞比亚', 'Zambia'],
    ['Зимбабве', 'Zimbabwe', '津巴布韦', 'Zimbabue'],
    ['Израиль', 'Israel', '以色列', 'Israel'],
    ['Индия', 'India', '印度', 'India'],
    ['Индонезия', 'Indonesia', '印度尼西亚', 'Indonesia'],
    ['Иордания', 'Jordan', '约旦', 'Jordania'],
    ['Ирак', 'Iraq', '伊拉克', 'Irak'],
    ['Иран', 'Iran', '伊朗', 'Irán'],
    ['Ирландия', 'Ireland', '爱尔兰', 'Irlanda'],
    ['Исландия', 'Iceland', '冰岛', 'Islandia'],
    ['Испания', 'Spain', '西班牙', 'España'],
    ['Италия', 'Italy', '意大利', 'Italia'],
    ['Йеменская Республика', 'Yemen', '也门', 'República de Yemen'],
    ['Кабо-Верде', 'Cape Verde', '佛得角', 'Cabo Verde'],
    ['Казахстан', 'Kazakhstan', '哈萨克斯坦', 'Kazajistán'],
    ['Камбоджа', 'Cambodia', '柬埔寨', 'Camboya'],
    ['Камерун', 'Cameroon', '喀麦隆', 'Camerún'],
    ['Канада', 'Canada', '加拿大', 'Canadá'],
    ['Катар', 'Qatar', '卡塔尔', 'Catar'],
    ['Кения', 'Kenya', '肯尼亚', 'Kenia'],
    ['Кипр', 'Cyprus', '塞浦路斯', 'Chipre'],
    ['Киргизия (Кыргызстан)', 'Kyrgyzstan', '吉尔吉斯斯坦', 'Kirguistán'],
    ['Китай', 'China', '中国', 'China'],
    ['КНДР', 'North Korea', '朝鲜', 'Corea del Norte'],
    ['Колумбия', 'Colombia', '哥伦比亚', 'Colombia'],
    [
      'Конго, Демократическая Республика',
      'Democratic Republic of the Congo',
      '刚果民主共和国',
      'República Democrática del Congo',
    ],
    [
      'Конго, Республика',
      'Republic of the Congo',
      '刚果共和国',
      'República del Congo',
    ],
    ['Коста-Рика', 'Costa Rica', '哥斯达黎加', 'Costa Rica'],
    ['Кот-д’Ивуар', 'Ivory Coast', '象牙海岸', 'Costa de Marfil'],
    ['Куба', 'Cuba', '古巴', 'Cuba'],
    ['Кувейт', 'Kuwait', '科威特', 'Kuwait'],
    ['Лаос', 'Laos', '老挝', 'Laos'],
    ['Латвия', 'Latvia', '拉脱维亚', 'Letonia'],
    ['Ливан', 'Lebanon', '黎巴嫩', 'Líbano'],
    ['Ливия', 'Libya', '利比亚', 'Libia'],
    ['Литва', 'Lithuania', '立陶宛', 'Lituania'],
    ['Люксембург', 'Luxembourg', '卢森堡', 'Luxemburgo'],
    ['Маврикий', 'Mauritius', '毛里求斯', 'Mauricio'],
    ['Мавритания', 'Mauritania', '毛里塔尼亚', 'Mauritania'],
    ['Малайзия', 'Malaysia', '马来西亚', 'Malasia'],
    ['Мальдивы', 'Maldives', '马尔代夫', 'Maldivas'],
    ['Мексика', 'Mexico', '墨西哥', 'México'],
    ['Мозамбик', 'Mozambique', '莫桑比克', 'Mozambique'],
    ['Монголия', 'Mongolia', '蒙古', 'Mongolia'],
    ['Морокко', 'Morocco', '摩洛哥', 'Marruecos'],
    ['Намибия', 'Namibia', '纳米比亚', 'Namibia'],
    ['Непал', 'Nepal', '尼泊尔', 'Nepal'],
    ['Нигер', 'Niger', '尼日尔', 'Níger'],
    ['Нигерия', 'Nigeria', '尼日利亚', 'Nigeria'],
    ['Норвегия', 'Norway', '挪威', 'Noruega'],
    ['ОАЭ', 'United Arab Emirates', '阿联酋', 'Emiratos Árabes Unidos'],
    ['Оман', 'Oman', '阿曼', 'Omán'],
    ['Пакистан', 'Pakistan', '巴基斯坦', 'Pakistán'],
    ['Парагвай', 'Paraguay', '巴拉圭', 'Paraguay'],
    ['Перу', 'Peru', '秘鲁', 'Perú'],
    ['Польша', 'Poland', '波兰', 'Polonia'],
    ['Португалия', 'Portugal', '葡萄牙', 'Portugal'],
    ['Россия', 'Russia', '俄罗斯', 'Rusia'],
    ['Руанда', 'Rwanda', '卢旺达', 'Ruanda'],
    ['Сальвадор', 'El Salvador', '萨尔瓦多', 'El Salvador'],
    ['Сан-Марино', 'San Marino', '圣马力诺', 'San Marino'],
    ['Саудовская Аравия', 'Saudi Arabia', '沙特阿拉伯', 'Arabia Saudita'],
    [
      'Северная Македония',
      'North Macedonia',
      '北马其顿',
      'Macedonia del Norte',
    ],
    ['Сингапур', 'Singapore', '新加坡', 'Singapur'],
    ['Сирия', 'Syria', '叙利亚', 'Siria'],
    ['Словакия', 'Slovakia', '斯洛伐克', 'Eslovaquia'],
    ['Словения', 'Slovenia', '斯洛文尼亚', 'Eslovenia'],
    ['Соломоновы Острова', 'Solomon Islands', '所罗门群岛', 'Islas Salomón'],
    ['Сомали', 'Somalia', '索马里', 'Somalia'],
    ['Судан', 'Sudan', '苏丹', 'Sudán'],
    ['Суринам', 'Suriname', '苏里南', 'Surinam'],
    ['Таджикистан', 'Tajikistan', '塔吉克斯坦', 'Tayikistán'],
    ['Таиланд', 'Thailand', '泰国', 'Tailandia'],
    ['Танзания', 'Tanzania', '坦桑尼亚', 'Tanzania'],
    ['Того', 'Togo', '多哥', 'Togo'],
    ['Тунис', 'Tunisia', '突尼斯', 'Túnez'],
    ['Туркменистан', 'Turkmenistan', '土库曼斯坦', 'Turkmenistán'],
    ['Турция', 'Turkey', '土耳其', 'Turquía'],
    ['Уганда', 'Uganda', '乌干达', 'Uganda'],
    ['Узбекистан', 'Uzbekistan', '乌兹别克斯坦', 'Uzbekistán'],
    ['Украина', 'Ukraine', '乌克兰', 'Ucrania'],
    ['Уругвай', 'Uruguay', '乌拉圭', 'Uruguay'],
    ['Филиппины', 'Philippines', '菲律宾', 'Filipinas'],
    ['Финляндия', 'Finland', '芬兰', 'Finlandia'],
    ['Франция', 'France', '法国', 'Francia'],
    ['Хорватия', 'Croatia', '克罗地亚', 'Croacia'],
    [
      'ЦАР',
      'Central African Republic',
      '中非共和国',
      'República Centroafricana',
    ],
    ['Чад', 'Chad', '乍得', 'Chad'],
    ['Черногория', 'Montenegro', '黑山', 'Montenegro'],
    ['Чехия', 'Czech Republic', '捷克共和国', 'República Checa'],
    ['Швеция', 'Sweden', '瑞典', 'Suecia'],
    ['Швейцария', 'Switzerland', '瑞士', 'Suiza'],
    ['Эквадор', 'Ecuador', '厄瓜多尔', 'Ecuador'],
    [
      'Экваториальная Гвинея',
      'Equatorial Guinea',
      '赤道几内亚',
      'Guinea Ecuatorial',
    ],
    [
      'Южноафриканская Республика',
      'South Africa',
      '南非',
      'República de Sudáfrica',
    ],
    ['Южная Корея', 'South Korea', '韩国', 'Corea del Sur'],
    ['Ямайка', 'Jamaica', '牙买加', 'Jamaica'],
    ['Япония', 'Japan', '日本', 'Japón'],
    ['Германия', 'Germany', '德国', 'Alemania'],
    ['Мадагаскар', 'Madagascar', '马达加斯加', 'Madagascar'],
    ['Мали', 'Mali', '马里', 'Malí'],
    ['Мальта', 'Malta', '马耳他', 'Malta'],
    ['Молдавия', 'Moldova', '摩尔多瓦', 'Moldavia'],
    ['Мьянма', 'Myanmar', '缅甸', 'Myanmar'],
    ['Нидерланды', 'Netherlands', '荷兰', 'Países Bajos'],
    ['Никарагуа', 'Nicaragua', '尼加拉瓜', 'Nicaragua'],
    ['Новая Зеландия', 'New Zealand', '新西兰', 'Nueva Zelanda'],
    ['Палестина', 'Palestine', '巴勒斯坦', 'Palestina'],
    ['Панама', 'Panama', '巴拿马', 'Panamá'],
    ['Румыния', 'Romania', '罗马尼亚', 'Rumanía'],
    ['Сейшельские острова', 'Seychelles', '塞舌尔', 'Seychelles'],
    ['Сенегал', 'Senegal', '塞内加尔', 'Senegal'],
    ['Сербия', 'Serbia', '塞尔维亚', 'Serbia'],
    ['США', 'USA', '美国', 'EEUU'],
    ['Тайвань', 'Taiwan', '台湾', 'Taiwán'],
    ['Чили', 'Chile', '智利', 'Chile'],
    ['Шри-Ланка', 'Sri Lanka', '斯里兰卡', 'Sri Lanka'],
    ['Эритрея', 'Eritrea', '厄立特里亚', 'Eritrea'],
    ['Эстония', 'Estonia', '爱沙尼亚', 'Estonia'],
    ['Эфиопия', 'Ethiopia', '埃塞俄比亚', 'Etiopía'],
  ],

  serviceData: [
    [
      // Russian
      {
        headerText: 'Доверенности',
        cardText:
          'Генеральная, на покупку-продажу недвижимости, на транспортное средство, на получение пенсии и др.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Загранпаспорт',
        cardText:
          'Подготовка заявления на оформление, консультация по перечню документов',
        cardImage: passportImage,
      },
      {
        headerText: 'Согласия, заявления, договоры',
        cardText: 'На выезд ребёнка, на продажу недвижимости для супруга и др.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Справки',
        cardText:
          'Об отсутствии судимости, для заключения брака с иностранцем, истребование документов и др.',
        cardImage: stampImage,
      },
      {
        headerText: 'Документы для юридических лиц',
        cardText: 'Доверенности, заявления для юридических лиц и др.',
        cardImage: buildingImage,
      },
      {
        headerText: 'Гражданство',
        cardText:
          'Оформление, в том числе для ребёнка, рождённого за рубежом, отказ от гражданства РФ и др.',
        cardImage: passportImage,
      },
      {
        headerText: 'Услуги ЗАГС',
        cardText:
          'Регистрация брака, расторжение брака, регистрация рождения ребенка, установление отцовства и др.',
        cardImage: marriageRingsImage,
      },
      {
        headerText: 'Другие консульские услуги',
        cardText:
          'Свидетельство о возвращении в Россию, легализация документов и др.',
        cardImage: courtImage,
      },
      {
        headerText: 'Консультационные услуги',
        cardText:
          'По вашему вопросу, в рамках законодательства России на территории иностранных государств',
        cardImage: balanceImage,
      },
    ],
    [
      // English
      {
        headerText: 'Power of attorney',
        cardText:
          'General, for real estate purchase and sale, for vehicles, for pension acquisition, and others',
        cardImage: documentBrown,
      },
      {
        headerText: 'Passport',
        cardText:
          'Preparation of application for registration, consultation on the list of documents',
        cardImage: passportImage,
      },
      {
        headerText: 'Consents, applications, contracts.',
        cardText:
          "For child's departure, for sale of real estate for spouse, etc",
        cardImage: documentBrown,
      },
      {
        headerText: 'Certificates',
        cardText:
          'About the absence of a criminal record, for marriage with a foreigner, requesting documents, etc.',
        cardImage: stampImage,
      },
      {
        headerText: 'Documents for legal entities',
        cardText:
          'Regarding absence of convictions, for marriage with a foreigner, about marital status, etc',
        cardImage: buildingImage,
      },
      {
        headerText: 'Citizenship',
        cardText:
          'Processing, including for a child born abroad, renunciation of Russian citizenship, etc',
        cardImage: passportImage,
      },
      {
        headerText: 'Registry Office Services',
        cardText:
          'Marriage registration, divorce, birth registration of a child, establishment of paternity, etc.',
        cardImage: marriageRingsImage,
      },
      {
        headerText: 'Additional consular services',
        cardText:
          'Certificate of return to Russia, legalization of documents, etc.',
        cardImage: courtImage,
      },
      {
        headerText: 'Consulting services',
        cardText:
          'Regarding your question, within the framework of Russian legislation on the territory of foreign countries',
        cardImage: balanceImage,
      },
    ],
    [
      // Chinese
      {
        headerText: '授权书',
        cardText: '一般授权，房地产买卖授权，交通工具授权，养老金申请等',
        cardImage: documentBrown,
      },
      {
        headerText: '护照',
        cardText: '申请登记的准备，咨询文件清单',
        cardImage: passportImage,
      },
      {
        headerText: '同意书、申请书、合同',
        cardText: '儿童出境同意书，配偶房地产出售同意书等',
        cardImage: documentBrown,
      },
      {
        headerText: '证明',
        cardText: '无犯罪记录证明，与外国人结婚的证明，请求文件等',
        cardImage: stampImage,
      },
      {
        headerText: '法律实体文件',
        cardText: '无犯罪记录的证明，配偶的结婚申请等',
        cardImage: buildingImage,
      },
      {
        headerText: '国籍',
        cardText: '申请国籍，包括国外出生的儿童，放弃俄罗斯国籍等',
        cardImage: passportImage,
      },
      {
        headerText: '民政局服务',
        cardText: '婚姻登记、离婚登记、出生登记、父权确认等',
        cardImage: marriageRingsImage,
      },
      {
        headerText: '其他领事服务',
        cardText: '返回俄罗斯的证明，文件的合法化等',
        cardImage: courtImage,
      },
      {
        headerText: '咨询服务',
        cardText: '关于您的问题，依据俄罗斯法律在外国的框架内',
        cardImage: balanceImage,
      },
    ],
    [
      // Spanish
      {
        headerText: 'Poder notarial',
        cardText:
          'General, para compra-venta de bienes raíces, para vehículos, para adquisición de pensiones, entre otros',
        cardImage: documentBrown,
      },
      {
        headerText: 'Pasaporte',
        cardText:
          'Preparación de solicitud de registro, consulta sobre la lista de documentos',
        cardImage: passportImage,
      },
      {
        headerText: 'Consentimientos, solicitudes, contratos',
        cardText:
          'Para la salida de un niño, para la venta de bienes raíces por parte del cónyuge, etc.',
        cardImage: documentBrown,
      },
      {
        headerText: 'Certificados',
        cardText:
          'Sobre la ausencia de antecedentes penales, para matrimonio con un extranjero, solicitud de documentos, etc.',
        cardImage: stampImage,
      },
      {
        headerText: 'Documentos para personas jurídicas',
        cardText:
          'Sobre la ausencia de condenas, para matrimonio con un extranjero, sobre estado civil, etc.',
        cardImage: buildingImage,
      },
      {
        headerText: 'Ciudadanía',
        cardText:
          'Tramitación, incluyendo para un niño nacido en el extranjero, renuncia a la ciudadanía rusa, etc.',
        cardImage: passportImage,
      },
      {
        headerText: 'Servicios de Registro Civil',
        cardText:
          'Registro de matrimonio, divorcio, registro de nacimiento de un niño, establecimiento de paternidad, etc.',
        cardImage: marriageRingsImage,
      },
      {
        headerText: 'Servicios consulares adicionales',
        cardText:
          'Certificado de regreso a Rusia, legalización de documentos, etc.',
        cardImage: courtImage,
      },
      {
        headerText: 'Servicios de consultoría',
        cardText:
          'Sobre su consulta, dentro del marco de la legislación rusa en el territorio de países extranjeros',
        cardImage: balanceImage,
      },
    ],
  ],
  serviceProcess: [
    [
      // Russian
      {
        headerText: 'Оформление заявки',
        cardText:
          'Выберите нужную услугу на сайте, оформите заявку и предоставьте требуемый список документов',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Подготовка текста документа',
        cardText:
          'Наш юрист свяжется c вами, подготовит документ и разместит  на портале КД МИД России',
        cardImage: documentBrown,
      },
      {
        headerText: 'Запись на прием в консульство',
        cardText:
          'Запишитесь в консульство на приём самостоятельно или с нашей помощью',
        cardImage: calendarImage,
      },
      {
        headerText: 'Получение готового документа',
        cardText:
          'В назначенный день приходите в консульство для оформления документа',
        cardImage: stampImage,
      },
    ],
    [
      // English
      {
        headerText: 'Submitting an application',
        cardText:
          'Select the required service on the website, submit an application, and provide the necessary documents',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparation of document text',
        cardText:
          'Our lawyer will contact you, prepare the document, and upload it to the portal of the Consular Department of the Ministry of Foreign Affairs of Russia',
        cardImage: documentBrown,
      },
      {
        headerText: 'Scheduling an appointment at the consulate',
        cardText:
          'Schedule an appointment at the consulate yourself or with our assistance',
        cardImage: calendarImage,
      },
      {
        headerText: 'Receiving the finalized document',
        cardText:
          'On the appointment date, come to the consulate to process the document',
        cardImage: stampImage,
      },
    ],
    [
      // Chinese
      {
        headerText: '提交申请',
        cardText: '在网站上选择所需服务，提交申请并提供必要的文件',
        cardImage: checkBoxImage,
      },
      {
        headerText: '准备文件文本',
        cardText:
          '我们的律师将与您联系，准备文件并上传到俄罗斯外交部领事部门的门户网站',
        cardImage: documentBrown,
      },
      {
        headerText: '预约领事馆',
        cardText: '自己预约领事馆，或在我们的协助下进行预约',
        cardImage: calendarImage,
      },
      {
        headerText: '领取已完成的文件',
        cardText: '在约定的日期，前往领事馆办理文件',
        cardImage: stampImage,
      },
    ],
    [
      // Spanish
      {
        headerText: 'Presentación de una solicitud',
        cardText:
          'Seleccione el servicio requerido en el sitio web, presente una solicitud y proporcione los documentos necesarios',
        cardImage: checkBoxImage,
      },
      {
        headerText: 'Preparación del texto del documento',
        cardText:
          'Nuestro abogado se pondrá en contacto con usted, preparará el documento y lo subirá al portal del Departamento Consular del Ministerio de Relaciones Exteriores de Rusia',
        cardImage: documentBrown,
      },
      {
        headerText: 'Programación de una cita en el consulado',
        cardText:
          'Programe una cita en el consulado usted mismo o con nuestra ayuda',
        cardImage: calendarImage,
      },
      {
        headerText: 'Recepción del documento finalizado',
        cardText:
          'En la fecha de la cita, dirígete al consulado para procesar el documento',
        cardImage: stampImage,
      },
    ],
  ],
  processingTiming: [
    'Срок подготовки документа:',
    'Document preparation time:',
    '文件准备时间：',
    'Tiempo de preparación del documento:',
  ],
  twoDays: [
    '2 рабочих дня с момента оплаты',
    '2 business days from the moment of payment',
    '自付款之日起2个工作日',
    '2 días hábiles a partir del momento de pago',
  ],
  paymentsMethods: [
    'Способы оплаты:',
    'Payment methods:',
    '付款方式：',
    'Métodos de pago:',
  ],

  possiblePayRussian: [
    'По запросу также возможна оплата российскими картами.',
    'Payment with Russian cards is also possible upon request.',
    '根据请求，也可以使用俄罗斯银行卡进行付款。',
    'El pago con tarjetas rusas también es posible a solicitud.',
  ],
  applicationSubmit: [
    'Оформить заявку',
    'Submit an application',
    '提交申请',
    'Presentar una solicitud',
  ],
  submit: ['Оформить', 'Submit', '提交', 'Enviar'],
  ask: ['Задать вопрос', 'Ask question', '提问', 'Hacer una pregunta'],
  //// Drawer Left
  listDrawerLeft: [
    [
      'Главная',
      'О нас',
      'Услуги',
      'Запись в консульство',
      'Связаться с нами',
      'Часто задаваемые вопросы',
      'Цены',
      'Блог',
    ],
    [
      'Main',
      'About Us',
      'Services',
      'Consulate Appointment',
      'Contact Us',
      'Frequently Asked Questions',
      'Prices',
      'Blog',
    ],
    ['主页', '关于我们', '服务', '领事预约', '联系我们', '常见问题', '价格', '博客'],
    [
      'Inicio',
      'Sobre nosotros',
      'Servicios',
      'Cita en el consulado',
      'Contáctenos',
      'Preguntas frecuentes',
      'Precios',
      'Blog',
    ],
  ],
  listDrawerLeftSubMenu: [
    [
      'Доверенности',
      'Загранпаспорт',
      'Согласия, заявления, договоры',
      'Справки',
      'Документы для юр. лиц',
      'Гражданство',
      'Услуги ЗАГС',
      'Другие консульские услуги',
      'Консультационные услуги',
    ],
    [
      'Powers of Attorney',
      'Passport (foreign)',
      'Consents, Statements, Contracts',
      'Certificates',
      'Documents for Legal Entities',
      'Citizenship',
      'Registry Office Services',
      'Other Consular Services',
      'Consulting Services',
    ],
    [
      '授权书',
      '护照（国外）',
      '同意书、声明、合同',
      '证明',
      '法律实体文件',
      '国籍',
      '民政局服务',
      '其他领事服务',
      '咨询服务',
    ],
    [
      'Poderes notariales',
      'Pasaporte (extranjero)',
      'Consentimientos, Declaraciones, Contratos',
      'Certificados',
      'Documentos para Personas Jurídicas',
      'Ciudadanía',
      'Servicios del Registro Civil',
      'Otros Servicios Consulares',
      'Servicios de Consultoría',
    ],
  ],
  footerList: [
    [
      'О нас',
      'Запись на приём в консульство',
      'Вопросы',
      'Цены',
      'Пользовательское соглашение',
      'Политика использования cookies',
      'Политика конфиденциальности',
    ],
    [
      'About us',
      'Appointment at the consulate',
      'Questions',
      'Price',
      'User Agreement',
      'Cookies Policy',
      'Privacy Policy',
    ],
    [
      '关于我们',
      '领事馆预约',
      '问题',
      '价格',
      '用户协议',
      'Cookie政策',
      '隐私政策',
    ],
    [
      'Sobre nosotros',
      'Cita en el consulado',
      'Preguntas',
      'Precio',
      'Acuerdo de usuario',
      'Política de cookies',
      'Política de privacidad',
    ],
  ],
  // About us
  aboutUsSection: ['o нас', 'about us', '关于我们', 'sobre nosotros'],
  aboutUsSection1: [
    'Consul online - это объединение профессионалов единомышленников, международных юристов с опытом работы российскими консульскими учреждениями и с иностранными правовыми организациями.',
    'Consul.online is an association of like-minded professionals, international lawyers with experience of working with Russian consular institutions and foreign legal organizations.',
    'Consul.online 是一个志同道合的专业人士、国际律师的协会，拥有与俄罗斯领事机构和外国法律组织合作的经验。',
    'Consul.online es una asociación de profesionales afines, abogados internacionales con experiencia en trabajar con instituciones consulares rusas y organizaciones legales extranjeras.',
  ],
  aboutUsSection2: [
    'Наши юристы подготовят документы в том формате, который требуется для подачи в консульство или посольство РФ, а также учитывает требования тех российских организаций, в которых эти документы впоследствии могут быть предоставлены.',
    'Our lawyers will prepare documents in the format required for their submission to the Russian consulate or embassy, taking into account the requirements of the Russian organizations to which these documents may subsequently be submitted.',
    '我们的律师将准备以提交给俄罗斯领事馆或大使馆所需的格式的文件，同时考虑到这些文件可能随后提交给的俄罗斯组织的要求。',
    'Nuestros abogados prepararán los documentos en el formato requerido para su presentación en el consulado o embajada rusa, teniendo en cuenta los requisitos de las organizaciones rusas a las que estos documentos pueden ser presentados posteriormente.',
  ],
  aboutUsSection3: [
    'Мы работаем за пределами России и понимаем с какими проблемами сталкиваются граждане России за рубежом.',
    'We operate outside of Russia and understand the challenges faced by Russian citizens abroad.',
    '我们在俄罗斯境外工作，了解俄罗斯公民在海外面临的挑战。',
    'Operamos fuera de Rusia y entendemos los desafíos que enfrentan los ciudadanos rusos en el extranjero.',
  ],
  aboutUsSection4: [
    'Этот проект создан для помощи нашим соотечественникам, проживающим по всему миру, а также гражданам других стран желающих получить юридическую услугу в рамках российского законодательства на территории иностранных государств без необходимости посещения РФ.',
    'This project has been created to assist our compatriots living around the world, as well as citizens of other countries who wish to obtain legal services within the framework of Russian legislation in foreign countries without the need to visit Russia.',
    '这个项目旨在帮助我们在全球各地生活的同胞，以及希望在外国境内根据俄罗斯法律获得法律服务而不需要访问俄罗斯的其他国家公民。',
    'Este proyecto ha sido creado para asistir a nuestros compatriotas que viven en todo el mundo, así como a los ciudadanos de otros países que deseen obtener servicios legales dentro del marco de la legislación rusa en el extranjero sin necesidad de visitar Rusia.',
  ],
  whyChooseUs: [
    'Почему выбирают нас?',
    'Why choose us?',
    '为什么选择我们？',
    '¿Por qué elegirnos?',
  ],

  enter: ['Войти', 'Enter', '登录', 'Ingresar'],
  orWith: ['Или с помощью', 'Or with', '或通过', 'O con'],
  email: ['Электронная почта', 'E-mail', '电子邮件', 'Correo electrónico'],
  enterPassword: [
    'Введите пароль',
    'Enter password',
    '输入密码',
    'Ingrese la contraseña',
  ],
  remember: ['Запомнить', 'Remember me', '记住我', 'Recuérdame'],
  forgetPassword: [
    'Забыли пароль?',
    'Forget password ?',
    '忘记密码？',
    '¿Olvidaste tu contraseña?',
  ],
  dontRegister: [
    'Еще не зарегистрированы?',
    'Not registered?',
    '还没有注册？',
    '¿Aún no registrado?',
  ],
  signUp: ['Зарегистрироваться', 'Sign up', '注册', 'Regístrate'],
  sent: ['запрос отправлен', 'request sent', '请求已发送', 'solicitud enviada'],
  regester: ['Регистрация', 'Sign up', '注册', 'Regístrate'],
  passwordRequirements: [
    'Минимум 8 символов, включая цифры и специальные символы',
    'Minimum 8 characters, including numbers and special characters',
    '至少8个字符，包括数字和特殊字符',
    'Mínimo 8 caracteres, incluidos números y caracteres especiales',
  ],
  consent: [
    'Я даю своё согласие на обработку персональных данных и соглашаюсь с условиями политики конфиденциальности',
    'I give my consent to the processing of personal data and agree to the terms of the privacy policy',
    '我同意处理个人数据，并同意隐私政策的条款',
    'Doy mi consentimiento para el procesamiento de datos personales y acepto los términos de la política de privacidad',
  ],
  registred: [
    'Уже зарегистрированы?',
    'Already registered?',
    '已经注册了吗？',
    '¿Ya registrado?',
  ],

  forgetPasswordText: [
    'Укажите адрес электронной почты, на который мы вышлем вам инструкцию для восстановления пароля',
    'Enter your email address to which we will send you instructions for password recovery',
    '输入您的电子邮件地址，我们将向您发送密码恢复说明',
    'Ingrese su dirección de correo electrónico a la que le enviaremos instrucciones para la recuperación de la contraseña',
  ],
  send: ['Отправить', 'Send', '发送', 'Enviar'],
  rememberedPassword: [
    'Вспомнили пароль?',
    'Is remember password?',
    '想起密码了吗？',
    '¿Recordaste la contraseña?',
  ],
  checkYourMail: [
    'Проверьте свою почту',
    'Check your email',
    '检查您的邮件',
    'Revisa tu correo electrónico',
  ],
  checkYourMailSent: [
    'Мы отправили вам на почту инструкцию по восстановлению пароля',
    'We have sent you instructions on how to reset your password by email.',
    '我们已向您的电子邮件发送了重置密码的说明',
    'Le hemos enviado instrucciones sobre cómo restablecer su contraseña por correo electrónico.',
  ],
  understand: ['Понятно', 'I understand', '明白', 'Entiendo'],
  errorConsent: [
    'Вы должны дать вам свое согласие',
    'You should give you your consent',
    '您必须给出您的同意',
    'Debes dar tu consentimiento',
  ],
  errorCredentiales: [
    'Неверные учетные данные',
    'Bad credentials',
    '无效凭据',
    'Credenciales inválidas',
  ],
  userExists: [
    'Пользователь существует, выберите другой адрес электронной почты',
    'User exists, choose another email',
    '用户已存在，请选择其他电子邮件地址',
    'El usuario existe, elige otro correo electrónico',
  ],
  emailError: [
    'Электронная почта имеет плохой формат',
    'Email is bad formed',
    '电子邮件格式错误',
    'El correo electrónico tiene un formato incorrecto',
  ],
  yes: ['Да', 'Yes', '是', 'Sí'],
  no: ['Нет', 'No', '否', 'No'],
  writeMessage: [
    'Введите сообщение...',
    'Type a message...',
    '输入信息...',
    'Escribe un mensaje...',
  ],
  chatPlaceholder: [
    'Привет! Я виртуальный консультант, готовый помочь вам в вопросах получения консульских услуг. Спрашивайте, что угодно — я здесь, чтобы сделать процесс максимально простым и понятным.',
    'Hello! I am a virtual consultant ready to assist you with consular services. Feel free to ask anything — I’m here to make the process as simple and clear as possible.',
    '你好！我是虚拟顾问，随时准备为您提供领事服务的帮助。请随意提问——我在这里为您尽可能简化和清晰地处理流程。',
    '¡Hola! Soy un consultor virtual, listo para ayudarte con servicios consulares. Pregunta lo que necesites, estoy aquí para hacer el proceso lo más simple y claro posible.',
  ],
  cookiePolicy: [
    'Политика использования файлов cookie',
    'Cookie Policy',
    'Cookie使用政策',
    'Política de cookies',
  ],
  termsOfUse: [
    'пользовательское соглашение',
    'terms of use',
    '用户协议',
    'términos de uso',
  ],
  contactUs: [
    'Вы также можете связаться с нашими сотрудниками',
    'You can also contact our employees',
    '您还可以联系到我们的员工',
    'También puedes contactar a nuestros empleados',
  ],
  passwordMismatch: [
    'Несовпадение паролей',
    'Password mismatch',
    '密码不匹配',
    'Coincidencia de contraseña',
  ],
  ready: ['Готово', 'Ready', '准备好了', 'Listo'],
  inProcess: ['В процессе', 'In process', '处理中', 'En proceso'],
  errorStripe: [
    'Ошибка, попробуйте позже',
    'Error, try later',
    '错误，请稍后再试',
    'Error, try later',
  ],
  agree: [
    'By clicking "Yes, I accept," you agree that Consul Online uses cookies to enhance your browsing experience, personalize content, provide social media features, and analyze our traffic. We may also share information with our advertising, analytics, and social media partners for their own purposes. You can manage your cookie settings by clicking the "View cookie settings" button. For more information on how we process your personal data, see our Cookie Policy or Privacy Policy.',
    'Нажимая «Да, я принимаю», вы соглашаетесь с тем, что Consul Online использует файлы cookie для улучшения вашего просмотра, персонализации контента, предоставления функций социальных сетей и анализа нашего трафика. Мы также можем передавать информацию нашим партнёрам по рекламе, аналитике и социальным сетям для их собственных целей. Вы можете управлять настройками файлов cookie, нажав кнопку «Просмотр настроек файлов cookie». Дополнительную информацию о том, как мы обрабатываем ваши персональные данные, смотрите в нашей Политике использования файлов cookie или Политике конфиденциальности.',
    'Al hacer clic en “Sí, acepto”, aceptas que Consul Online utilice cookies para mejorar tu experiencia de navegación, personalizar los contenidos, proporcionar funciones de redes sociales y analizar nuestro tráfico. También podemos compartir información con nuestros socios publicitarios, de análisis y de redes sociales para sus propios fines. Puedes gestionar tu configuración de cookies haciendo clic en el botón “Ver configuración de cookies”. Para obtener más información sobre cómo tratamos tus datos personales, consulta nuestra Política de cookies o nuestra Política de privacidad.',
    '单击“是，我接受”，即表示您同意 Consul Online 使用 cookies 来改善您的浏览体验、个性化内容、提供社交媒体功能并分析我们的流量。我们还可能与我们的广告、分析和社交媒体合作伙伴共享信息，以用于他们自己的目的。您可以单击“查看 Cookie 设置”按钮来管理您的 Cookie 设置。有关我们如何处理您的个人数据的更多信息，请参阅我们的《Cookie 政策》或《隐私政策》。',
  ],
  disagree: ['Disagree', 'Не согласен', 'No estoy de acuerdo', '不同意'],
  agreeYes: ['Agree', 'Согласен', 'Estoy de acuerdo', '同意'],
  weValue: [
    'We value your privacy',
    'Мы ценим вашу конфиденциальность',
    'Valoramos tu privacidad',
    '我们重视您的隐私',
  ],
}

export default vocabulary

import { useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { GlobalContextWrapper } from './GlobalContext'
import Paths from './Paths'
import theme from './theme'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DialogModalProvider } from './contexts/DialogModalContext'

function App() {
  useEffect(() => {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      window.dataLayer.push(arguments)
    }

    gtag('js', new Date())
    gtag('config', 'G-2PPG1RKB3Y')
    gtag('config', 'AW-11324059798')
    gtag('event', 'conversion', {
      send_to: 'AW-11324059798/LiYvCPH-z_MYEJbh3Zcq',
    })

    const script = document.createElement('script')
    script.src = 'https://mc.yandex.ru/metrika/tag.js'
    script.async = true
    script.onload = () => {
      if (window.ym) {
        window.ym(99057239, 'init', {
          clickmap: false,
          trackLinks: false,
          accurateTrackBounce: false,
          webvisor: false,
        })
      }
    }
    document.body.appendChild(script)
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <GlobalContextWrapper>
        <DialogModalProvider>
          <ThemeProvider theme={theme}>
            <Paths />
          </ThemeProvider>
        </DialogModalProvider>
      </GlobalContextWrapper>
    </LocalizationProvider>
  )
}

export default App

import serviceAxios from './serviceAxios'

export async function postUserIp(data) {
  try {
    const response = await serviceAxios.post('/public/get-ip', data, {
      headers: {
        'Content-Type': 'multipart/JSON',
      },
    })

    return response.data.ipFinded.country
  } catch (error) {
    console.error('An error occurred:', error)

    return 'error'
  }
}
export async function changeUserIp(data) {
  try {
    const response = await serviceAxios.post('/public/change-ip', data, {
      headers: {
        'Content-Type': 'multipart/JSON',
      },
    })

    return response.data
  } catch (error) {
    console.error('An error occurred:', error)

    return 'error'
  }
}

import { useCallback } from 'react'

export const EventType = {
  SUBMIT_AN_APPLICATION_CLICK: 'conversion_event_outbound_click',
  WA_CLICK: 'conversion_event_outbound_click_1',
  TELEGRAM_CLICK: 'conversion_event_outbound_click_2',
  PURCHASE_SCREEN: 'conversion_event_outbound_click_3',

  // Экран "Спасибо" или "Платеж успешно совершен" - после отправления заявки
  SUCCESS_SCREEN: 'conversion_event_outbound_click_5',

  SUBMIT_AN_APPLICATION_FROM_SERVICE_CLICK: 'conversion_event_outbound_click_4',
}

const EventNameByType = {
  [EventType.SUBMIT_AN_APPLICATION_CLICK]: 'Отправить заявку',
  [EventType.WA_CLICK]: 'Клик по кнопке WhatsApp',
  [EventType.TELEGRAM_CLICK]: 'Клик по кнопке Telegram',
  [EventType.PURCHASE_SCREEN]: 'Экран оплаты заказа',
  [EventType.SUCCESS_SCREEN]: 'Успешное отправление заявки',
  [EventType.SUBMIT_AN_APPLICATION_FROM_SERVICE_CLICK]:
    'Отправить заявку из сервиса',
}

/**
 * Хук для отправки событий в Google Analytics (gtag)
 * @param params Дополнительные параметры
 * @param eventAction Действие события из перечисления GtagEventAction
 */
export const useSendEvent = () => {
  const sendEvent = useCallback(async (eventAction, params = {}) => {
    if (typeof window !== 'undefined' && window.gtag) {
      if (process.env.NODE_ENV !== 'development') {
        window.gtag('event', eventAction, params)
      }

      console.log(
        `Событие: %c${EventNameByType[eventAction]}%c залогировано!`,
        'color: #fff; background: #007acc; padding: 2px 6px; border-radius: 4px;',
        'color: #000; background: none; padding: 0; border-radius: 0px;',
      )
      Object.keys(params).length > 0 &&
        console.log(
          `Параметры события: %c ${JSON.stringify(params)}`,
          'color: #007acc;',
        )
    } else {
      console.warn('gtag не инициализирован!')
    }
  }, [])

  return { sendEvent }
}

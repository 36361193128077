import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useContext, lazy, Suspense, useEffect } from 'react'
import { isBrowser } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'

import GlobalContext from './GlobalContext'

import PaymentSucsess from 'pages/PaymentSuccess'
import Loading from 'pages/Loading'
import vocabulary from 'vocabulary/vocabulary'
import { postUserIp } from 'api/getIp'

const InfoIp = lazy(() => import('pages/InfoIp'))
const Orders = lazy(() => import('pages/Orders'))
const AdmList = lazy(() => import('pages/components/AdmList'))
const Users = lazy(() => import('pages/Users'))

const Layout300 = lazy(() => import('./pages/Layouts/Layout300'))
const Main599 = lazy(() => import('./pages/599/Main599'))
const Main600 = lazy(() => import('./pages/600/Main600'))
const Main819 = lazy(() => import('./pages/819/Main819'))
const Main947 = lazy(() => import('./pages/947/Main947'))
const Main1239 = lazy(() => import('./pages/1239/Main1239'))
const Main1439 = lazy(() => import('./pages/1439/Main1439'))

const AboutUs599 = lazy(() => import('./pages/599/AboutUs599'))
const AboutUs600 = lazy(() => import('./pages/600/AboutUs600'))
const AboutUs819 = lazy(() => import('./pages/819/AboutUs819'))
const AboutUs947 = lazy(() => import('./pages/947/About947'))
const AboutUs1239 = lazy(() => import('./pages/1239/AboutUs1239'))
const AboutUs1439 = lazy(() => import('./pages/1439/AboutUs1439'))

const PowerOfAttorney599 = lazy(() => import('./pages/599/PowerOfAttorney599'))
const PowerOfAttorney600 = lazy(() => import('./pages/600/PowerOfAttorney600'))
const PowerOfAttorney819 = lazy(() => import('./pages/819/PowerOfAttorney819'))
const PowerOfAttorney947 = lazy(() => import('./pages/947/PowerOfAttorney947'))
const PowerOfAttorney1239 = lazy(
  () => import('./pages/1239/PowerOfAttorney1239'),
)
const PowerOfAttorney1439 = lazy(
  () => import('./pages/1439/PowerOfAttorney1439'),
)

const Passport599 = lazy(() => import('./pages/599/Passport599'))
const Passport600 = lazy(() => import('./pages/600/Passport600'))
const Passport819 = lazy(() => import('./pages/819/Passport819'))
const Passport947 = lazy(() => import('./pages/947/Passport947'))
const Passport1239 = lazy(() => import('./pages/1239/Passport1239'))
const Passport1439 = lazy(() => import('./pages/1439/Passport1439'))

const Consent599 = lazy(() => import('./pages/599/Consent599'))
const Consent600 = lazy(() => import('./pages/600/Consent600'))
const Consent819 = lazy(() => import('./pages/819/Consent819'))
const Consent947 = lazy(() => import('./pages/947/Consent947'))
const Consent1239 = lazy(() => import('./pages/1239/Consent1239'))
const Consent1439 = lazy(() => import('./pages/1439/Consent1439'))

const Certificates599 = lazy(() => import('./pages/599/Certificates599'))
const Certificates600 = lazy(() => import('./pages/600/Certificates600'))
const Certificates819 = lazy(() => import('./pages/819/Certificates819'))
const Certificates947 = lazy(() => import('./pages/947/Certificates947'))
const Certificates1239 = lazy(() => import('./pages/1239/Certificates1239'))
const Certificates1439 = lazy(() => import('./pages/1439/Certificates1439'))

const LegalEntities599 = lazy(() => import('./pages/599/LegalEntities599'))
const LegalEntities600 = lazy(() => import('./pages/600/LegalEntities600'))
const LegalEntities819 = lazy(() => import('./pages/819/LegalEntities819'))
const LegalEntities947 = lazy(() => import('./pages/947/LegalEntities947'))
const LegalEntities1239 = lazy(() => import('./pages/1239/LegalEntities1239'))
const LegalEntities1439 = lazy(() => import('./pages/1439/LegalEntities1439'))

const Home599 = lazy(() => import('./pages/599/PrivateScreens/Home599'))
const Zags599 = lazy(() => import('./pages/599/Zags599'))
const Zags600 = lazy(() => import('./pages/600/Zags600'))
const Zags819 = lazy(() => import('./pages/819/Zags819'))
const Zags947 = lazy(() => import('./pages/947/Zags947'))
const Zags1239 = lazy(() => import('./pages/1239/Zags1239'))
const Zags1439 = lazy(() => import('./pages/1439/Zags1439'))

const Citizenship599 = lazy(() => import('./pages/599/Citizenship599'))
const Citizenship600 = lazy(() => import('./pages/600/Citizenship600'))
const Citizenship819 = lazy(() => import('./pages/819/Citizenship819'))
const Citizenship947 = lazy(() => import('./pages/947/Citizenship947'))
const Citizenship1239 = lazy(() => import('./pages/1239/Citizenship1239'))
const Citizenship1439 = lazy(() => import('./pages/1439/Citizenship1439'))

const Other599 = lazy(() => import('./pages/599/Other599'))
const Other600 = lazy(() => import('pages/600/Other600'))
const Other819 = lazy(() => import('pages/819/Other819'))
const Other947 = lazy(() => import('pages/947/Other947'))
const Other1239 = lazy(() => import('pages/1239/Other1239'))
const Other1439 = lazy(() => import('pages/1439/Other1439'))

const Consulting599 = lazy(() => import('pages/599/Consulting599'))
const Consulting600 = lazy(() => import('pages/600/Consulting600'))
const Consulting819 = lazy(() => import('pages/819/Consulting819'))
const Consulting947 = lazy(() => import('pages/947/Consulting947'))
const Consulting1239 = lazy(() => import('pages/1239/Consulting1239'))
const Consulting1439 = lazy(() => import('pages/1439/Consulting1439'))

const Appointment599 = lazy(() => import('pages/599/Appointment599'))

const Appointment600 = lazy(() => import('pages/600/Appointment600'))
const Appointment819 = lazy(() => import('pages/819/Appointment819'))
const Appointment947 = lazy(() => import('pages/947/Appointment947'))
const Appointment1239 = lazy(() => import('pages/1239/Appointment1239'))
const Appointment1439 = lazy(() => import('pages/1439/Appointment1439'))

const FaqPage599 = lazy(() => import('pages/599/FaqPage599'))
const FaqPage600 = lazy(() => import('pages/600/FaqPage600'))
const FaqPage819 = lazy(() => import('pages/819/FaqPage819'))
const FaqPage947 = lazy(() => import('pages/947/FaqPage947'))
const FaqPage1239 = lazy(() => import('pages/1239/FaqPage1239'))
const FaqPage1439 = lazy(() => import('pages/1439/FaqPage1439'))

const Prices599 = lazy(() => import('pages/599/Prices599'))
const Prices600 = lazy(() => import('pages/600/Prices600'))
const Prices819 = lazy(() => import('pages/819/Prices819'))
const Prices947 = lazy(() => import('pages/947/Prices947'))
const Prices1239 = lazy(() => import('pages/1239/Prices1239'))
const Prices1439 = lazy(() => import('pages/1439/Prices1439'))

const Home600 = lazy(() => import('pages/600/PrivateScreens/Home600'))
const Home819 = lazy(() => import('pages/819/PrivateScreens/Home819'))
const Home947 = lazy(() => import('pages/947/PrivateScreens/Home947'))
const Home1239 = lazy(() => import('pages/1239/PrivateScreens/Home1239'))
const Home1439 = lazy(() => import('pages/1439/PrivateScreens/Home1439'))

const PrivacyPolicy599 = lazy(() => import('pages/599/PrivacyPolicy599'))
const PrivacyPolicy600 = lazy(() => import('pages/600/PrivacyPolicy600'))
const PrivacyPolicy819 = lazy(() => import('pages/819/PrivacyPolicy819'))
const PrivacyPolicy947 = lazy(() => import('pages/947/PrivacyPolicy947'))
const PrivacyPolicy1239 = lazy(() => import('pages/1239/PrivacyPolicy1239'))
const PrivacyPolicy1439 = lazy(() => import('pages/1439/PrivacyPolicy1439'))

const Request = lazy(() => import('pages/Request/Request'))

const UserAgreement599 = lazy(() => import('pages/599/UserAgreement599'))
const UserAgreement600 = lazy(() => import('pages/600/UserAgreement600'))
const UserAgreement819 = lazy(() => import('pages/819/UserAgreement819'))
const UserAgreement947 = lazy(() => import('pages/947/UserAgreement947'))
const UserAgreement1239 = lazy(() => import('pages/1239/UserAgreement1239'))
const UserAgreement1439 = lazy(() => import('pages/1439/UserAgreement1439'))

const CookiePolicy599 = lazy(() => import('pages/599/CookiePolicy599'))
const CookiePolicy600 = lazy(() => import('pages/600/CookiePolicy600'))
const CookiePolicy819 = lazy(() => import('pages/819/CookiePolicy819'))
const CookiePolicy947 = lazy(() => import('pages/947/CookiePolicy947'))
const CookiePolicy1239 = lazy(() => import('pages/1239/CookiePolicy1239'))
const CookiePolicy1439 = lazy(() => import('pages/1439/CookiePolicy1439'))
const PaymentRandom = lazy(() => import('pages/PaymentRandom'))

export default function Paths() {
  let is599 = useMediaQuery({ minWidth: 278, maxWidth: 599 })
  let is600 = useMediaQuery({ minWidth: 600, maxWidth: 819 })
  const is819 = useMediaQuery({ minWidth: 820, maxWidth: 947 })
  const is947 = useMediaQuery({ minWidth: 948, maxWidth: 1239 })
  const is1239 = useMediaQuery({ minWidth: 1240, maxWidth: 1439 })
  const is1439 = useMediaQuery({ minWidth: 1440 })
  let isNoScreen = useMediaQuery({ maxWidth: 277 })

  const { isLoggedIn, langSelected, state, isLoading, GlobalActions } =
    useContext(GlobalContext)

  let countryRequest = async () => {
    let returnedCountry = await postUserIp()
    console.log(returnedCountry)
    let index = vocabulary.countries.findIndex(country =>
      country.includes(returnedCountry),
    )
    
    if (vocabulary.euCountryIndexes.includes(index)) {
      await GlobalActions.setCurrency('eur')
    } else {
      GlobalActions.setCurrency('usd')
    }

    if (index !== -1) {
      GlobalActions.setCountry(returnedCountry)
      GlobalActions.setLocalizedCountry(
        vocabulary.countries[index][langSelected],
      )
    }
  }

  useEffect(() => {
    countryRequest()
  }, [langSelected])

  let Main,
    AboutUs,
    PowerOfAttorney,
    Passport,
    Consent,
    Certificates,
    LegalEntities,
    Home,
    Zags,
    Citizenship,
    Other,
    Consulting,
    Appointment,
    Faq,
    Prices,
    UserAgreement,
    CookiePolicy,
    PrivacyPolicy

  Main =
    AboutUs =
    PowerOfAttorney =
    Passport =
    Consent =
    Certificates =
    LegalEntities =
    Home =
    Zags =
    Citizenship =
    Other =
    Consulting =
    Appointment =
    Faq =
    Prices =
    UserAgreement =
    CookiePolicy =
    PrivacyPolicy =
      Layout300

  if (is599) {
    Main = Main599
    PowerOfAttorney = PowerOfAttorney599
    AboutUs = AboutUs599
    Passport = Passport599
    Consent = Consent599
    Certificates = Certificates599
    LegalEntities = LegalEntities599
    Zags = Zags599
    Citizenship = Citizenship599
    Other = Other599
    Consulting = Consulting599
    Appointment = Appointment599
    Faq = FaqPage599
    Prices = Prices599
    Home = Home599
    UserAgreement = UserAgreement599
    CookiePolicy = CookiePolicy599
    PrivacyPolicy = PrivacyPolicy599
  } else if (is600) {
    Main = Main600
    AboutUs = AboutUs600
    PowerOfAttorney = PowerOfAttorney600
    Passport = Passport600
    Consent = Consent600
    Certificates = Certificates600
    LegalEntities = LegalEntities600
    Zags = Zags600
    Citizenship = Citizenship600
    Other = Other600
    Consulting = Consulting600
    Appointment = Appointment600
    Faq = FaqPage600
    Prices = Prices600
    Home = Home600
    CookiePolicy = CookiePolicy600
    UserAgreement = UserAgreement600
    PrivacyPolicy = PrivacyPolicy600
  } else if (is819) {
    Main = Main819
    AboutUs = AboutUs819
    PowerOfAttorney = PowerOfAttorney819
    Passport = Passport819
    Consent = Consent819
    Certificates = Certificates819
    LegalEntities = LegalEntities819
    Zags = Zags819
    Citizenship = Citizenship819
    Other = Other819
    Consulting = Consulting819
    Appointment = Appointment819
    Faq = FaqPage819
    Prices = Prices819
    Home = Home819
    CookiePolicy = CookiePolicy819
    UserAgreement = UserAgreement819
    PrivacyPolicy = PrivacyPolicy819
  } else if (is947) {
    Main = Main947
    AboutUs = AboutUs947
    PowerOfAttorney = PowerOfAttorney947
    Passport = Passport947
    Consent = Consent947
    Certificates = Certificates947
    LegalEntities = LegalEntities947
    Zags = Zags947
    Citizenship = Citizenship947
    Other = Other947
    Consulting = Consulting947
    Appointment = Appointment947
    Faq = FaqPage947
    Prices = Prices947
    Home = Home947
    CookiePolicy = CookiePolicy947
    UserAgreement = UserAgreement947
    PrivacyPolicy = PrivacyPolicy947
  } else if (is1239) {
    Main = Main1239
    AboutUs = AboutUs1239
    PowerOfAttorney = PowerOfAttorney1239
    Passport = Passport1239
    Consent = Consent1239
    Certificates = Certificates1239
    LegalEntities = LegalEntities1239
    Zags = Zags1239
    Citizenship = Citizenship1239
    Other = Other1239
    Consulting = Consulting1239
    Appointment = Appointment1239
    Faq = FaqPage1239
    Prices = Prices1239
    Home = Home1239
    UserAgreement = UserAgreement1239
    CookiePolicy = CookiePolicy1239
    PrivacyPolicy = PrivacyPolicy1239
  } else if (is1439) {
    Main = Main1439
    AboutUs = AboutUs1439
    PowerOfAttorney = PowerOfAttorney1439
    Passport = Passport1439
    Consent = Consent1439
    Certificates = Certificates1439
    LegalEntities = LegalEntities1439
    Zags = Zags1439
    Citizenship = Citizenship1439
    Other = Other1439
    Consulting = Consulting1439
    Appointment = Appointment1439
    Faq = FaqPage1439
    Prices = Prices1439
    Home = Home1439
    UserAgreement = UserAgreement1439
    CookiePolicy = CookiePolicy1439
    PrivacyPolicy = PrivacyPolicy1439
  } else if (isNoScreen) {
    Passport =
      Main =
      AboutUs =
      PowerOfAttorney =
      Zags =
      LegalEntities =
      Certificates =
      Citizenship =
      Other =
      Consulting =
      Appointment =
      Faq =
      PrivacyPolicy =
        Layout300
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <>
            {isLoading ? (
              <>
                <Route path='/' element={<Loading />} />
              </>
            ) : (
              <>
                {isLoggedIn ? (
                  <>
                    <Route path='/home' element={<Home />}></Route>
                  </>
                ) : null}
                <Route
                  path='/'
                  element={
                    <Main isBrowser={isBrowser} langPath={langSelected} />
                  }
                />
                <Route
                  path='/en'
                  element={<Main isBrowser={isBrowser} langPath={1} />}
                />
                <Route
                  path='/cn'
                  element={<Main isBrowser={isBrowser} langPath={2} />}
                />
                <Route
                  path='/es'
                  element={<Main isBrowser={isBrowser} langPath={3} />}
                />
                <Route path='/about' element={<AboutUs />} />
                <Route path='/payments_recieved' element={<PaymentSucsess />} />
                <Route
                  path='/payment-random'
                  element={
                    <Suspense fallback={<Loading />}>
                      <PaymentRandom />
                    </Suspense>
                  }
                />
                <Route
                  path='/poa/*'
                  element={
                    <Suspense fallback={<Loading />}>
                      <PowerOfAttorney />
                    </Suspense>
                  }
                />
                <Route
                  path='/passport/*'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Passport />
                    </Suspense>
                  }
                />
                <Route
                  path='/consent/*'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Consent />
                    </Suspense>
                  }
                />
                <Route
                  path='/certificates/*'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Certificates />
                    </Suspense>
                  }
                />
                <Route
                  path='/legal-entities/*'
                  element={
                    <Suspense fallback={<Loading />}>
                      <LegalEntities />
                    </Suspense>
                  }
                />
                <Route
                  path='/zags/*'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Zags />
                    </Suspense>
                  }
                />
                <Route
                  path='/citizenship/*'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Citizenship />
                    </Suspense>
                  }
                />
                <Route
                  path='/other/*'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Other />
                    </Suspense>
                  }
                />
                <Route
                  path='/consulting/*'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Consulting />
                    </Suspense>
                  }
                />
                <Route
                  path='/appointment'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Appointment />
                    </Suspense>
                  }
                />
                <Route
                  path='/faq'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Faq />
                    </Suspense>
                  }
                />
                <Route
                  path='/prices'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Prices />
                    </Suspense>
                  }
                />
                <Route
                  path='/user-agreement'
                  element={
                    <Suspense fallback={<Loading />}>
                      <UserAgreement />
                    </Suspense>
                  }
                />
                <Route
                  path='/cookie-policy'
                  element={
                    <Suspense fallback={<Loading />}>
                      <CookiePolicy />
                    </Suspense>
                  }
                />
                <Route
                  path='/requests/:id'
                  element={
                    <Suspense fallback={<Loading />}>
                      <Request />
                    </Suspense>
                  }
                />
                <Route
                  path='/privacy-policy'
                  element={
                    <Suspense fallback={<Loading />}>
                      <PrivacyPolicy />
                    </Suspense>
                  }
                />
                {state.userInfo?.role === 'admin' ||
                state.userInfo?.role === 'su' ? (
                  <>
                    <Route 
                      path='/private/orders' 
                      element={
                        <Suspense fallback={<Loading />}>
                          <Orders />
                        </Suspense>
                      } 
                    />
                    <Route 
                      path='/private/info-ip' 
                      element={
                        <Suspense fallback={<Loading />}>
                          <InfoIp />
                        </Suspense>
                      }
                    />
                    <Route 
                      path='/private/users' 
                      element={
                        <Suspense fallback={<Loading />}>
                          <Users />
                        </Suspense>
                      }
                    />

                    {state.userInfo?.role === 'su' ? (
                      <Route
                        path='/private/get-adm-list'
                        element={
                          <Suspense fallback={<Loading />}>
                            <AdmList />
                          </Suspense>
                        }
                      />
                    ) : null}
                  </>
                ) : null}
                <Route path='*' element={<Main isBrowser={isBrowser} />} />
              </>
            )}
          </>
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

import { useState } from 'react'

import { DialogModalWindow } from 'components/DialogModalWindow/DialogModalWindow'
import { useNavigate } from 'react-router-dom'

import PaymentMade from './components/Workflow/PaymentMade'

const PaymentSucsess = () => {
  const [open, setOpen] = useState(true)
  const navigate = useNavigate()

  const handleClose = () => {
    setOpen(false)
    navigate('/')
  }

  return (
    <DialogModalWindow open={open}>
      <PaymentMade handleClose={handleClose} />
    </DialogModalWindow>
  )
}

export default PaymentSucsess
